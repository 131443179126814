<template>
  <v-container>
    <v-toolbar flat color="toolbar-action transparent">
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" :to="'/subgroups/new?group_id=' + this.group_id">New</v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="subgroups"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';

export default {
  name: 'GroupSubGroupList',
  components: {
    ConfirmDialog
  },
  mounted() {
    this.load();
  },
  watch: {
    group_id: function () {
      this.load();
    }
  },
  props: ['group_id'],
  computed: {
    ...mapGetters('subgroup', ['loading', 'error', 'subgroups', 'meta'])
  },
  methods: {
    editItem(item) {
      this.$router.push({ path: `/subgroups/${item.id}` });
    },
    deleteItem(item) {
      this.subgroup_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('subgroup/delete', this.subgroup_id);
      this.load();
    },
    load() {
      if (this.group_id) {
        store.dispatch('subgroup/list', { page: 1, group_id: this.group_id });
      }
    }
  },
  data: () => ({
    headers: [
      { text: 'Title', value: 'title' },
      { text: 'Status', value: 'status' },
      { text: 'Actual Students', value: 'actual_students' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ],
    subgroup_id: null,
    showDeleteConfirmDialog: false
  })
};
</script>
